//REWRITTEN STYLES
app-monitoring-chat-list .monitoring-chat-list__table {
  .p-datatable {
    .p-datatable-thead {
      th:first-child {
        padding-left: 0px;
      }
    }

    .p-datatable-tbody>tr>td {
      vertical-align: top;
    }

    .p-datatable-tbody>tr>td:first-child {
      padding-left: 0px;
    }

    .p-datatable-tbody>tr:hover {
      background: none;
      cursor: default;
    }

    &>.p-datatable-wrapper {
      overflow: auto;
    }

    ::-webkit-scrollbar {
      border-radius: 8px !important;
      background-color: #fff;
      padding-left: 10px;
    }

    &>.p-datatable-wrapper::-webkit-scrollbar {
      display: block !important;
    }
  }
}
