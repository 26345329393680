// DIALOG REWRITTEN STYLES FOR SPECIFIC CLASSES, PAGES, ELEMENTS
.custom-monitoring-dialog {
  .p-dialog-header .p-dialog-title {
    font-family: "Roboto", "Helvetica Neue", sans-serif !important;
    color: #414141;
    font-size: 18px;
    line-height: 22px;
  }
}

.custom-monitoring-dialog.dialog-mobile-resolution.p-dialog {
  margin: 0;
}

.custom-monitoring-dialog.scrollbar {
  .p-dialog-content {
    padding-right: 0px;
    padding-bottom: 0px;
  }
}