@import "variables";

//P-BUTTON GENERAL REWRITTEN STYLES
@mixin button-font-settings {
  font-family: "Roboto", "Helvetica Neue", sans-serif !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
}

// P-BUTTON REWRITTEN STYLES FOR SPECIFIC CLASSES, PAGES, ELEMENTS
app-monitoring-empty-state .monitoring_button,
app-create-monitoring-chat .monitoring_button,
app-edit-monitoring-chat .monitoring_button,
app-monitoring-page .monitoring_button,
app-delete-dialog .delete-modal__button  {
  .p-button {
    border: none;
    border-radius: 12px;
    padding: 11px 24px;
    display: flex;
    justify-content: center;
    @include button-font-settings;
  }

  .p-button-label {
    @include button-font-settings;
  }
}

app-monitoring-empty-state .monitoring__add-button.p-element,
app-monitoring-page .monitoring__add-button.p-element {
  .p-button {
    padding: 11px 16px;
    color: $primary-accent;
    background-color: $background-light;
  }

  .p-button:focus {
    box-shadow: 0 0 0 1px $background-light !important;
  }
}

app-create-monitoring-chat .monitoring__create-button.p-element {
  .p-button {
    width: 131px;
    height: 40px;
  }

  .p-button:focus {
    box-shadow: 0 0 0 1px $primary-accent !important;
  }
}

app-create-monitoring-chat .monitoring__copy-button.p-element {
  .p-button {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent !important;
  }

  .p-button:active {
    img {
      width: 23px;
      height: 23px;
    }
  }

  .p-button:focus {
    box-shadow: none;
  }
}

app-edit-monitoring-chat .monitoring__edit-button.p-element,
app-edit-monitoring-chat .monitoring__cancel-button.p-element,
app-delete-dialog .delete-modal__button.p-element {
  .p-button:focus {
    box-shadow: 0 0 0 1px $primary-accent !important;
  }
}

app-edit-monitoring-chat .monitoring__edit-button.p-element,
app-edit-monitoring-chat .monitoring__cancel-button.p-element {
  .p-button {
    width: 242px;
    height: 40px;
  }
}

app-edit-monitoring-chat .monitoring__cancel-button.p-element,
app-delete-dialog .delete-modal__button.p-element {
  .p-button, .p-button:active {
    background-color: $background-light;
    color: $primary-accent;
    border: none;
  }

  .p-button:hover {
    background-color: rgba($black, 0.1);
    color: $primary-accent;
  }

  .p-button:focus {
    box-shadow: 0 0 0 1px $background-light !important;
  }
}

app-monitoring-chat-list .monitoring-chat-list__button.p-element {
  .p-button {
    border-radius: 50%;
    padding: 12px;
    border: none;
  }

  .p-button:not(:hover):not(:focus):not(:active) {
    background: none;
  }
  
  .p-button.p-button-danger.p-button-text:enabled:hover {
    border-radius: 50%;
  }
}

app-monitoring-chat-list .monitoring-chat-list__edit-button.monitoring-chat-list__button.p-element {
  .p-button:focus {
    box-shadow: none;
  }
}

app-delete-dialog .delete-modal__button.p-element {
  .p-button {
    width: 225px;
    height: 40px;
  }
}

app-delete-dialog .delete-modal__confirm-button.p-element {
  .p-button {
    color: $red !important;
  }
}

@media screen and (min-width: 801px) {
  app-edit-monitoring-chat .monitoring__edit-button.p-element,
  app-edit-monitoring-chat .monitoring__cancel-button.p-element {
    .p-button {
      width: 242px;
      height: 40px;
    }
  }

  app-delete-dialog .delete-modal__button.p-element {
    .p-button {
      width: 225px;
      height: 40px;
    }
  }
}

@media screen and (max-width: 800px) {
  app-edit-monitoring-chat .monitoring__edit-button.p-element,
  app-edit-monitoring-chat .monitoring__cancel-button.p-element,
  app-delete-dialog .delete-modal__button.p-element {
    width: 47.5%;

    .p-button {
      width: 100%;
    }
  }
}