// REWRITTEN STYLES
app-monitoring-page .monitoring-page__card.p-element {
    .p-card {
      height: 100%;
      box-shadow: none !important;
      
      &-body {
        height: 100%;
      }

      &-content {
        padding: 0;
        height: 100%;
      }
    }
}