//REWRITTEN STYLES
app-edit-monitoring-chat .edit-monitoring-modal__minute-input {
  .p-inputnumber input {
    width: 76px;
    height: 40px;
    border-radius: 12px;
    border: 1px solid #E6E6E9;
    text-align: center;
    padding: 11px 20px 11px 16px;
  }

  .p-inputtext:disabled {
    border-color: #E6E6E9 !important;
  }
}